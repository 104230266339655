import React, { useEffect, useRef, useState } from 'react';
import Layout from '../components/layout';
import Section from '../components/Section';
import { Link } from 'gatsby';

const AboutPage = (props) => {

	return (
		<Layout>
			<div className="home-wrapper">
				<Section bg={{type: `color`, color: `#000`}} classes="not-center text-left min-max-height padding-x-small">
					{/* <div className="main-title-wrapper">
						<h1 className="main-title uppercase">
							{home.first_title}
							<br/>-<br/>
							{home.second_title}
						</h1>
					</div> */}

					<Link className="home-button" to="/">Back</Link>

					<div className="about-content mb-big">
						<h2>
							Why Trust?
						</h2>
						<p>
							Every time a new technology is announced one question arises: can we trust it?
						</p>
						<p>
							The topic of trust hardly makes the headlines, but our interpersonal relations depend on it as much as trade deals among countries. And when one factor lays at the foundation of both civilization and economy, there are few doubts: trust is the currency of our times. So, when blockchain made its appearance with the claim of being trust-less we could not help but wonder: how is this going to change the shape of trust?
						</p>
						<p>
							<em>Trust in the Blockchain Society</em> is a documentary about how we trust technologies, and how these technologies shape the way we trust each other. Through the voices and insights of a diverse group of experts, Trust in the Blockchain Society explores the many ways in which blockchain is changing how we organize information, how we build consensus, and ultimately how we design the future of our social institutions.
						</p>
						<h2>
							What is a smartphone documentary?
						</h2>
						<p>
							We call it <em>smartphone documentary</em> because every interaction has been designed from the smartphone up. 
						</p>
						<p>
							When people experience content through a mobile device, the information they retain is very different from other media: they do learn faster, but as long as the experience is absolutely seamless. Every second of lag, every unnecessary interruption may cause attention to drop and jeopardize the understanding. This is why we crafted the experience around two pivotal aspects: making the topic accessible, and the specific experience of content on a smartphone.
						</p>
						<p>
							The interface uses familiar interactions – scrolling and tilting – now combined together to enhance comprehension. The intuitive interaction with your device becomes an elegant transition between reading and watching, a seamless flow of information creating a tailored rhythm in the person’s hand: whether the linear narrative we curated, or the non-linear storylines that users can create for themselves.
						</p>
						<p>
							Translating a highly abstract topic into a compelling story, our goal is to bring the viewer to the centre of the conversation, to discuss the present and future of this revolutionary technology.
						</p>
					</div>
				</Section>
			</div>
		</Layout>
	);
};

export default AboutPage;
